import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "../screens/home";
import Dashboard from "../screens/dashboard";
import Profile from "../screens/profiles";

import { ListDocuments, ViewDocuments, UploadDocuments } from "../screens/documents";
import { PipeLineList, PipeLineDetails, PipeLineForm1003, PipeLineRequests } from "../screens/pipelines";
import {
  LeadList, LeadDetails, LeadForm1003, LeadRequests,
  LeadDocumentLists, LeadDocumentView
} from "../screens/leads";

import Campaign from "../screens/campaign";
import Reports from "../screens/reports";
import Contacts from "../screens/contacts";
import MortgageRates from "../screens/mortgagerates";

import Session from "../session";

// Form1003 Stages
// import { AppStage1, AppStage2, AppStage3, AppStage4, AppStage5, AppStage6 } from "../screens/form1003/stages";

// Document Links
/* import {
  ApproveDocuments,
  UploadDocuments,
  ViewDocuments,
} from "../screens/documents"; */

const RouteLinks = [
  { id: 1, path: "", Component: Home },
  { id: 2, path: "dashboard", Component: Dashboard, Session: true },

  { id: 3, path: "lead", Component: LeadList, Session: true },
  { id: 11, path: "leadd", Component: LeadDetails, Session: true },
  { id: 19, path: "leadf", Component: LeadForm1003, Session: true },
  { id: 19, path: "leadr", Component: LeadRequests, Session: true },
  { id: 20, path: "leadp", Component: LeadDocumentLists, Session: true },
  { id: 21, path: "leadv", Component: LeadDocumentView, Session: true, idparam: true },
  { id: 4, path: "pipe", Component: PipeLineList, Session: true },
  { id: 5, path: "piped", Component: PipeLineDetails, Session: true },
  { id: 24, path: "pipef", Component: PipeLineForm1003, Session: true },
  { id: 25, path: "piper", Component: PipeLineRequests, Session: true },
  { id: 6, path: "doclist", Component: ListDocuments, Session: true },
  { id: 6, path: "docview", Component: ViewDocuments, Session: true, idparam: true },
  { id: 7, path: "uploaddoc", Component: UploadDocuments, Session: true },
  { id: 8, path: "campaign", Component: Campaign, Session: true },
  { id: 9, path: "reports", Component: Reports, Session: true },
  { id: 10, path: "profile", Component: Profile, Session: true },
  { id: 22, path: "contacts", Component: Contacts, Session: true },
  { id: 23, path: "mortgagerates", Component: MortgageRates, Session: true },


  /*  { id: 12, path: "stage1", Component: AppStage1, Session: true },
   { id: 13, path: "stage2", Component: AppStage2, Session: true },
   { id: 14, path: "stage3", Component: AppStage3, Session: true },
   { id: 15, path: "stage4", Component: AppStage4, Session: true },
   { id: 16, path: "stage5", Component: AppStage5, Session: true },
   { id: 17, path: "stage6", Component: AppStage6, Session: true },
  { id: 52, path: "docapprove", Component: ApproveDocuments, Session: true },
  { id: 53, path: "docview", Component: ViewDocuments, Session: true },
  { id: 54, path: "docupload", Component: UploadDocuments, Session: true },
  { id: 57, path: "saved", Component: SavedList, Session: true },
  { id: 58, path: "download", Component: CompletedList, Session: true },
   */
];

function PrivateRoute({ children }) {
  let loggedin = Session.IsLoggedIn();
  return loggedin ? children : <Navigate to="/" />;
}

const RendorRoutes = () => {
  return (
    <Routes>
      {RouteLinks.map((x) => {
        if (x.Session) {
          if (x.idparam) {
            return (
              <Route
                key={x.id}
                path={"/" + x.path + "/:id"}
                exact
                element={
                  <PrivateRoute>
                    <x.Component />
                  </PrivateRoute>
                }
              />
            );
          }
          return (
            <Route
              key={x.id}
              path={"/" + x.path}
              exact
              element={
                <PrivateRoute>
                  <x.Component />
                </PrivateRoute>
              }
            />
          );
        } else {
          return (
            <Route
              key={x.id}
              path={"/" + x.path}
              exact
              element={<x.Component />}
            />
          );
        }
      })}
    </Routes>
  );
};

export default RendorRoutes;
