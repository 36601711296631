import { React, Helper, Support, DropDown2 as DropDown, TextInput2 as TextInput } from "../../common";
import { SetPreapproval } from "../../services/preapprovalApi";

const loanDetails = [
    { id: 1, label: "First Name", name: "firstName", path: "stage3.borrower", controlType: "TEXT" },
    { id: 2, label: "Last Name", name: "lastName", path: "stage3.borrower", controlType: "TEXT" },
    { id: 3, label: "Date of Birth", name: "birthDate", path: "stage3.borrower", type: "DOB", controlType: "TEXT" },
    { id: 31, label: "Email Address", name: "emailAddressText", path: "stage9", type: "EMAIL", controlType: "TEXT" },
    { id: 4, label: "Phone Type", name: "phoneType", path: "stage3.borrower", controlType: "DROPDOWN", lookUps: Support.PhoneTypes() },
    { id: 5, label: "Phone Number", name: "mobilePhone", path: "stage3.borrower", type: "PHONE", controlType: "TEXT" },
    { id: 6, label: "Current Address", name: "addressText", path: "stage7.borrower", controlType: "TEXT" },
    { id: 7, label: "City", name: "addressCity", path: "stage7.borrower", controlType: "TEXT" },
    { id: 8, label: "State", name: "addressState", path: "stage7.borrower", controlType: "DROPDOWN", lookUps: "STATELIST" },
    { id: 9, label: "Zip", name: "postalCode", path: "stage7.borrower", type: "ZIP", controlType: "TEXT" },
    { id: 10, label: "Mobile Number", name: "mobilePhone", path: "stage7.borrower", type: "PHONE", controlType: "TEXT" },
    { id: 11, label: "Social Security Number", name: "taxIdentificationIdentifier", path: "stage7.borrower", type: "SSN", controlType: "TEXT" },
    { id: 12, label: "Military Service", name: "militaryServiceIndicator", path: "stage3.borrower", controlType: "DROPDOWN", lookUps: Support.OptionTypes(true) },
    { id: 13, label: "Credit Score", name: "creditScore", path: "stage7.borrower", controlType: "DROPDOWN", lookUps: Support.CreditTypes() },
    { id: 14, label: "What does the lead say for the process he is in?", name: "processIndicatorText", path: "stage1", controlType: "DROPDOWN", lookUps: Support.Processes(true) },
    { id: 15, label: "When does the lead plan purchasing the property?", name: "propertyPlanIndicatorText", path: "stage1", controlType: "DROPDOWN", lookUps: Support.PropertyPlan(true) },
    { id: 16, label: "How Does the lead want us to help him?", name: "helpItemIndicatorText", path: "stage1", controlType: "DROPDOWN", lookUps: Support.HelpItems(true) },
    { id: 17, label: "Property Address", name: "propertyAddressText", path: "stage2", controlType: "TEXT" },
    { id: 18, label: "Property Type", name: "propertyIndicatorText", path: "stage2", controlType: "DROPDOWN", lookUps: Support.PropertyTypes(true) },
    { id: 19, label: "Property Use", name: "propertyUseIndicatorText", path: "stage2", controlType: "DROPDOWN", lookUps: Support.PropertyUses(true) },
    { id: 20, label: "Estimated Price", name: "propertyPriceIndicatorText", path: "stage2", type: "FINANCE", controlType: "TEXT" },
    { id: 21, label: "Down payment", name: "propertyDownPayment", path: "stage2", type: "FINANCE", controlType: "TEXT" },
    { id: 22, label: "Mortgage Applied For", name: "propertyMortgageAppliedText", path: "stage2", controlType: "DROPDOWN", lookUps: Support.MortgageTypes() },
    { id: 23, label: "Total Annual Gross Income", name: "totalAnnualGrossIncome", path: "stage4.borrower", type: "FINANCE", controlType: "TEXT" },
    { id: 24, label: "Total Monthly Debt Payments", name: "totalMonthlyDebtPayments", path: "stage4.borrower", type: "FINANCE", controlType: "TEXT" },
    { id: 25, label: "Savings Accounts", name: "savingsAccount", path: "stage9.borrower", type: "FINANCE", controlType: "TEXT" },
    { id: 26, label: "Retirement Accounts", name: "retirementAccount", path: "stage9.borrower", type: "FINANCE", controlType: "TEXT" },
    { id: 27, label: "Stock and Bonds", name: "stockAndBondAccounts", path: "stage9.borrower", type: "FINANCE", controlType: "TEXT" },
    { id: 28, label: "Gifts from Relatives", name: "gitfAccounts", path: "stage9.borrower", type: "FINANCE", controlType: "TEXT" },
    { id: 29, label: "Sales of Other Real Estate", name: "realEstateAccounts", path: "stage9.borrower", type: "FINANCE", controlType: "TEXT" },
    { id: 30, label: "Other (Business Account)", name: "otherAccounts", path: "stage9.borrower", type: "FINANCE", controlType: "TEXT" },
];

const RenderInputColumns = ({ items, onInputChanged, OnBlurError, errors, OnInputClicked, statesList }) => {
    if (items && items.childs && items.childs.length > 0) {
        return (
            items.childs.map((x) => {
                if (x.controlType === "TEXT") {
                    return <li className={"contentIIcol"} key={x.id}>
                        <div className="pop_formContentHead">{x.label}</div>
                        <div className="popTxtField">
                            <TextInput
                                path={`${x.path}.${x.name}`}
                                value={Helper.ToString(x.value)}
                                onInputChildChanged={onInputChanged}
                                errors={errors}
                                onBlurError={OnBlurError}
                                onInputClicked={OnInputClicked}
                                required={true}
                                validate={true}
                                requiredMessage={`${x.label} is required`}
                                validationMessage={`Enter Vald ${x.label}`}
                                dataRequired={true}
                                valuetype={x.type || undefined}
                            />
                        </div>
                    </li>
                } else if (x.controlType === "DROPDOWN" && x.name !== 'addressState') {
                    return <li className={"contentIIcol"} key={x.id}>
                        <div className="pop_formContentHead">{x.label}</div>
                        <div className="popSelect">
                            <DropDown
                                path={`${x.path}.${x.name}`}
                                value={Helper.ToString(x.value)}
                                items={x.lookUps}
                                displayName={"label"}
                                displayValue={"dvalue"}
                                onInputChildChanged={onInputChanged}
                                indexValue={1}
                                errors={errors}
                                onBlurError={OnBlurError}
                                requiredMessage={`${x.label} is required`}
                                onInputClicked2={OnInputClicked}
                                required={true}
                                dataRequired={true}
                                data-sel-msg={`${x.label} is required`}
                            />
                        </div>
                    </li>
                } else if (x.controlType === "DROPDOWN" && x.name === 'addressState') {
                    return <li className={"contentIIcol"} key={x.id}>
                        <div className="pop_formContentHead">{x.label}</div>
                        <div className="popSelect">
                            <DropDown
                                path={`${x.path}.${x.name}`}
                                value={Helper.ToString(x.value)}
                                items={statesList}
                                displayName={"text"}
                                displayValue={"value"}
                                onInputChildChanged={onInputChanged}
                                indexValue={1}
                                errors={errors}
                                onBlurError={OnBlurError}
                                requiredMessage={`${x.label} is required`}
                                onInputClicked2={OnInputClicked}
                                required={true}
                                dataRequired={true}
                                data-sel-msg={`${x.label} is required`}
                            />
                        </div>
                    </li>
                } else {
                    return null;
                }
            }
            )
        );

    }
    return null;
}

const Component = (props) => {

    const { display, onCloseClicked, onSubmittedNewLead, statesList } = props;

    const [preHeaders, setPreHeaders] = React.useState({ childs: loanDetails });
    const [row, setRow] = React.useState({});
    const [state, setState] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [error, setError] = React.useState(null);

    const OnRecordValidate = () => {
        setError("");
        setErrors([]);
        const { errors, valid } = Helper.IsFormValid('pop_addlead');

        if (!valid) {
            setErrors(errors);
            setError("Fill all required details");
            return false;
        }
        return true;
    }

    const onSubmitClicked = async (e) => {
        e.preventDefault();
        setErrors([]);
        if (!OnRecordValidate()) return;
        global.Busy(true);
        row.loanOfficerId = "";
        let rslt = await SetPreapproval(row);
        global.Busy(false);
        if (rslt.status === 100) {
            if (onSubmittedNewLead) onSubmittedNewLead(true);
        } else {
            setErrors(rslt.errors);
            setError(rslt.statusText);
        }
    };

    const OnCloseClicked = (e) => {
        e.preventDefault();
        setError("");
        setErrors([]);
        if (onCloseClicked) onCloseClicked(e);
    };

    const OnInputChanged = async (path, value, index) => {
        let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        setRow(newRow);
        setState(!state);
        OnUpdateLoanDetails(path, value);
    };

    const OnUpdateLoanDetails = (path, value) => {
        let tmp = path.replace(/_/g, '.');
        let _headers = preHeaders.childs;
        let tmp2 = _headers.map((x) => { return `${x.path}.${x.name}` });
        let _index = tmp2.findIndex((x) => x === tmp);
        if (_index > -1) {
            _headers[_index].value = value;
        }
        setPreHeaders({ childs: _headers });
    }

    const OnBlurError = (name, val, clear) => {
        let _errors = [];
        if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
        if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
        setErrors(_errors);
        setError(val);
    };

    const OnInputClicked = (name) => {
        let _err = null;
        if (errors && errors.length > 0) {
            let _findIndex = errors.findIndex((x) => x.field === name);
            if (_findIndex > -1) {
                _err = errors[_findIndex].value;
            }
        }
        setError(_err);
    };

    if (display) {
        return (
            <div id="pop_addlead" className="pop_disable_bg" style={{ display: "block" }}>
                <div className="addLead_pop" style={{ top: 400 }}>
                    <div className="pop_head_bgRed">Add New Lead</div>
                    <div className="pop_contentArea" style={{ maxHeight: 550 }}>
                        <ul className="contentIIcol">
                            <RenderInputColumns
                                items={preHeaders}
                                onInputChanged={OnInputChanged}
                                OnBlurError={OnBlurError}
                                OnInputClicked={OnInputClicked}
                                errors={errors}
                                statesList={statesList}
                            />
                        </ul>
                    </div>
                    <div className="pop_buttonLabel_borderTop_Red">
                        <input type="button" value="Submit" className="pop_button_bgRed" onClick={(e) => onSubmitClicked(e)} />
                        <input type="button" value="Close" className="pop_button_bgRed" onClick={(e) => OnCloseClicked(e)} />
                    </div>
                    {error && <div className="errormessage_center">{error}</div>}
                </div>
            </div >
        );
    }
    return null;
};

export default Component;