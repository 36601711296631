import { React, Helper, Link, TextInput2 as TextInput, RadioInput2 as RadioInput, DropDown2 as DropDown } from "../../../../common";

const RenderNumberOfCoApplicant = (props) => {
    const { num, row, errors, onInputChanged, onBlurError, onInputClicked } = props;
    return (
        <li className="formContentIVcol">
            <div className="form1003ContentHead">Co-Applicant {num} Name</div>
            <div className="formMandatory">*</div>
            <div className="usericon"></div>
            <TextInput
                errors={errors}
                path={`applicants.${num}.borrower.firstName`}
                value={Helper.GetJsonValue(row, `applicants.${num}.borrower.firstName`)}
                onInputChildChanged={onInputChanged}
                required={true}
                onBlurError={onBlurError}
                onInputClicked={onInputClicked}
                requiredMessage={'Co-Applicant Name is required'}
                dataRequired={true}
            />
        </li>
    );
}

const Component = (props) => {
    const { row, loanOffierDisabled, errors, onInputChanged, loanOfficers, howdoyouhear,
        onBlurError, onInputClicked } = props;

    return (
        <>
            <div className="form1003BlkHead">
                <div className="formHeadContent">Other Details</div>
                <div className="form1003Blk_right">&nbsp;</div>
            </div>
            <div className="form1003ContentBlk">
                <ul className="formContentIVcol">
                    <li className="formContentIVcol">
                        <div className="form1003ContentHead">
                            Type of Credit
                        </div>
                        <div className="formMandatory">*</div>
                        <ul className="form_A" data-opts={true} data-req-msg={"Type of Credit?"} data-id={`borrowerCreditType`}>
                            <RadioInput
                                errors={errors}
                                name={"borrowerCreditType"}
                                value={Helper.ToString(row?.borrowerCreditType)}
                                options={Helper.TypeOfCredits()}
                                uselabel={true}
                                onInputChildChanged={onInputChanged}
                            />
                        </ul>
                        {/* <div style="color:red">If No is selected, there will be 5 steps. Step 4 from top has to be removed</div> */}
                    </li>
                    {row?.borrowerCreditType === "Joint" && (
                        <>
                            <li className="formContentIVcol">
                                <div className="form1003ContentHead">
                                    Is there a Co-Applicant?
                                </div>
                                <Link
                                    to="#"
                                    title="Info"
                                    onClick={(e) => window.pop("info_coapplicant")}
                                >
                                    <div className="formTip"></div>
                                </Link>
                                <ul className="form_A" data-opts={true} data-req-msg={"Is there a Co-Applicant?"} data-id={`coApplicantIndicator`}>
                                    <RadioInput
                                        errors={errors}
                                        path={"coApplicantIndicator"}
                                        value={Helper.ToUndefinedCheck(row?.coApplicantIndicator)}
                                        options={Helper.Options()}
                                        uselabel={false}
                                        onInputChildChanged={onInputChanged}
                                    />
                                </ul>
                                {/* <div style="color:red">If No is selected, there will be 5 steps. Step 4 from top has to be removed</div> */}
                            </li>
                            {row?.coApplicantIndicator && (
                                <>
                                    <li className="formContentIVcol">
                                        <div className="form1003ContentHead">Total Number of Co-Applicants</div><div className="formMandatory">*</div>
                                        <div className="hastagicon"></div>
                                        <DropDown
                                            errors={errors}
                                            path={"coApplicantCount"}
                                            value={Helper.ToString(row?.coApplicantCount)}
                                            items={Helper.NumberList(1, 5)}
                                            displayName={"text"}
                                            displayValue={"value"}
                                            onInputChildChanged={onInputChanged}
                                            onInputClicked={onInputClicked}
                                            dataRequired={true}
                                            requiredMessage={"Total Number of Co-Applicants"}
                                            indexValue={1}
                                        />
                                    </li>
                                    {[...Array(Helper.GetApplicantCount(row)).keys()].map((x) => { return <RenderNumberOfCoApplicant key={x} {...props} num={x + 1} /> })}
                                </>
                            )}
                        </>
                    )}


                    <li className="formContentIVcol" style={{ display: "none" }}>
                        <div className="form1003ContentHead">
                            Are you working with a Loan Officer?
                        </div>
                        <ul className="form_A" data-opts={true} data-req-msg={"Are you working with a Loan Officer?"} data-id={`loanOfficerIndicator`}>
                            <RadioInput
                                errors={errors}
                                path={"loanOfficerIndicator"}
                                value={Helper.ToUndefinedCheck(row?.loanOfficerIndicator)}
                                options={Helper.Options()}
                                uselabel={false}
                                onInputChildChanged={onInputChanged}
                            />
                        </ul>
                    </li>

                    {row?.loanOfficerIndicator && (
                        <li className="formContentIVcol">
                            <div className="form1003ContentHead">Loan Officer Name</div>
                            <div className="usericon"></div>
                            <DropDown
                                errors={errors}
                                path={"loanOfficerId"}
                                value={Helper.ToString(row?.loanOfficerId)}
                                items={loanOfficers}
                                displayName={"text"}
                                displayValue={"value"}
                                onInputChildChanged={onInputChanged}
                                disabled={
                                    !Helper.ToUndefinedCheck(row?.loanOfficerIndicator) || loanOffierDisabled
                                }
                                dataRequired={true}
                                requiredMessage={"Loan Officer Name is required"}
                                indexValue={1}
                                onInputClicked={onInputClicked}
                            />
                        </li>
                    )}

                    <li className="formContentIVcol">
                        <div className="form1003ContentHead">
                            How did you hear about us?
                        </div>
                        <div className="noteicon"></div>
                        <DropDown
                            errors={errors}
                            path={"howDoYouHear"}
                            value={Helper.ToString(row?.howDoYouHear)}
                            items={howdoyouhear}
                            displayName={"text"}
                            displayValue={"value"}
                            onInputChildChanged={onInputChanged}
                            onInputClicked={onInputClicked}
                            dataRequired={true}
                            requiredMessage={"How did you hear about us?"}
                            indexValue={1}
                        />
                    </li>
                    {row?.howDoYouHear === "Other" && (
                        <li className="formContentIVcol">
                            <div className="form1003ContentHead">Others</div>
                            <div className="textPad10">
                                <TextInput
                                    errors={errors}
                                    path={"howDoYouHearOthers"}
                                    value={Helper.ToString(row?.howDoYouHearOthers)}
                                    onInputChildChanged={onInputChanged}
                                    onBlurError={onBlurError}
                                    onInputClicked={onInputClicked}
                                    required={true}
                                    requiredMessage={'How did you hear about us?'}
                                    dataRequired={true}
                                />
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </>
    );
};

export default Component;