import { React, Link, Container, Helper, Session, useNavigate } from "../../../common";
import { GetOfficersLoans } from "../../../services/loanApi";

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const Navigate = useNavigate();

    const OnLoanClicked = (e, id) => {
        e.preventDefault();
        Session.StoreAsync('floanid', id);
        Navigate("/piped");
    }

    const FetchResults = async () => {
        global.Busy(true);
        setRows([]);
        let rslt = await GetOfficersLoans();
        if (rslt.status === 100) {
            setRows(rslt.data);
        }
        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        setRows([]);
        Session.RemoveAsync('floanid');
        Session.RemoveAsync('ploanid');
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix_10">
                    <div id="pipelineBlk">
                        <div className="pipelineContentBlk">
                            <div className="pipeline_headblk">
                                <div className="blkName">Pipeline</div>
                                <div className="head_right">
                                    {/* <div className="rightVerBlock">
                                        <div className="filterSelectBox_100">
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT" disabled>Program</option>
                                                <option value="#">30 Year Fixed</option>
                                                <option value="#">15 Year Fixed</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="rightVerBlock">
                                        <div className="filterSelectBox_150">
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT" disabled>Purpose</option>
                                                <option value="#">Primary Residence</option>
                                                <option value="#">Renovation</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="rightVerBlock" style={{ display: "none" }}>
                                        <div className="filterSelectBox_120">
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT" disabled>Loan Number</option>
                                                <option value="#">0123456789</option>
                                                <option value="#">2345678901</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="rightVerBlock">
                                        <div className="documentTxtSearch"><input type="text" placeholder="Search by Loan No" /></div>
                                        <input type="button" className="searchicon" />
                                    </div>
                                </div>
                            </div>

                            <div className="pipeline_filteredBlk" style={{ display: "none" }}>
                                <div className="pipeline_filter"><b>Loan Number:</b> 0123456789<div className="fliter_close"><Link to="#" title="Close">&#215;</Link></div></div>
                                <div className="pipeline_filter"><b>Rate Lock:</b> Yes<div className="fliter_close"><Link to="#" title="Close">&#215;</Link></div></div>
                                <div className="pipeline_filter"><b>Loan Status:</b> Active<div className="fliter_close"><Link to="#" title="Close">&#215;</Link></div></div>
                                <input type="button" className="pipeline_button_clear" value="Clear All" />
                            </div>

                            <div className="contentarea_table">
                                {rows && rows.length > 0 ? (
                                    <>
                                        <div className="pipelineListTable_head">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Sl.</td>
                                                        <td>Loan ID</td>
                                                        <td>Name</td>
                                                        <td>Email ID</td>
                                                        <td>Phone</td>
                                                        <td>Purpose</td>
                                                        <td>Property Details</td>
                                                        <td>Program</td>
                                                        <td>Amount</td>
                                                        <td>Created Date</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="pipelineListTable">
                                            <table>
                                                <tbody>
                                                    {rows && rows.map((x, index) => (
                                                        <tr key={x.id}>
                                                            <td>{index + 1}</td>
                                                            <td><Link to="#" onClick={(e) => OnLoanClicked(e, x.formid)}>{x.loannumber}</Link></td>
                                                            <td>{Helper.GetStringJoin(x, ['first_name', 'last_name'])}</td>
                                                            <td>{x.email}</td>
                                                            <td>{x.mobile_number}</td>
                                                            <td>{x.purpose}</td>
                                                            <td>{x.propaddress}</td>
                                                            <td>{x.loantype}</td>
                                                            <td>{Helper.USCurrencyFormat(x.loanamount)}</td>
                                                            <td>{Helper.ToDateFormat(x.createdt, 'YYYY-MMM-DD', true)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                ) : (<div className="noContent">Please search by loan number</div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

        </>
    );
};

export default Component;
