import { React, Link, useLocation, Helper } from "../../common";
import { GetShortUserProfile } from "../../services/profileApi";

const IsActive = (mnu, icon) => {
    let loc = useLocation().pathname;

    if (loc.startsWith("/leadd")) loc = "details";
    if (loc.startsWith("/leadf")) loc = "form1003";
    if (loc.startsWith("/leadr")) loc = "requests";
    if (loc.startsWith("/leadp")) loc = "predocs";
    if (loc.startsWith("/leadv")) loc = "predocs";

    if (loc.startsWith("/piped")) loc = "details";
    if (loc.startsWith("/pipef")) loc = "form1003";
    if (loc.startsWith("/piper")) loc = "requests";
    if (loc.startsWith("/pipep")) loc = "predocs";

    const isActiveMenu = mnu === loc;
    if (icon) {
        return isActiveMenu ? `${icon} active` : icon;
    }
    return isActiveMenu ? "leftPanelNavi current" : "leftPanelNavi";
};

const Component = ({ id, loanNumber, form1003, onUserInfoLoaded }) => {
    const [initlized, setInitlized] = React.useState(false);
    const [row, setRow] = React.useState({});

    React.useEffect(() => {
        if (!Helper.IsNullValue(id)) setInitlized(true);
    }, [id]);

    const FetchResults = async () => {
        global.Busy(true);
        let rslt = await GetShortUserProfile(id);
        setRow({});
        if (rslt.status === 100) {
            let tmp = rslt.data;
            tmp.id = id;
            setRow(tmp);
            if (onUserInfoLoaded) onUserInfoLoaded(tmp);
        }
        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        setRow({});
        FetchResults();
    }
    return (
        <div>
            <Link to={form1003 ? "/pipe" : "/lead"} title="Back to Leads List"><input type="button" value="&#10094;" className="backButton" /></Link>
            <ul className="leftPanel_BorDetails_Blk">
                <li className="leftPanel_BorDetails_Blk">
                    <ul className="leftPanelLoanNo">
                        <li className="leftPanelLoanNo">{form1003 ? "Loan ID:" : "Lead ID:"}</li>
                        <li className="leftPanelLoanNo">{loanNumber}</li>
                    </ul>
                </li>
                <li className="leftPanel_BorDetails_Blk">
                    <ul className="borrowerDetails">
                        <li className="borrowerDetails">{Helper.GetStringJoin(row, ['first_name', 'last_name'])}</li>
                        <li className="borrowerDetails">Member Since: {Helper.ToDateFormat(row?.createdt, "YYYY-MM-DD")}</li>
                        <li className="borrowerDetailsIIcol"><div className="phborrowerIcon"></div></li>
                        <li className="borrowerDetailsIIcol">{row?.cell_phone}</li>
                        <li className="borrowerDetailsIIcol"><div className="emailborrowerIcon"></div></li>
                        <li className="borrowerDetailsIIcol" title={row?.email}>{row?.email}</li>
                    </ul>
                </li>
            </ul>
            <ul className="leftPanelNavi">
                <Link to={form1003 ? "/piped" : "/leadd"}>
                    <li className={IsActive("details")}>
                        <div className={IsActive("details", "inviteBorrowerIcon")}></div>{form1003 ? "Loan Details" : "Lead Details"}
                    </li>
                </Link>
                <Link to={form1003 ? "/pipef" : "/leadf"}>
                    <li className={IsActive("form1003")}>
                        <div className={IsActive("form1003", "fillFormIcon")}></div>Form 1003
                    </li>
                </Link>
                <Link to={form1003 ? "/piper" : "/leadr"}>
                    <li className={IsActive("requests")}>
                        <div className={IsActive("requests", "requestBorrowerIcon")}></div>Requests
                    </li>
                </Link>
                {!form1003 && (
                    <Link to="/leadp">
                        <li className={IsActive("predocs")}>
                            <div className={IsActive("predocs", "preDocIcon")}></div>Pre Docs
                        </li>
                    </Link>
                )}
            </ul>
        </div>
    );
};

export default Component;