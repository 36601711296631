import { React, Link, Helper, Container, useNavigate, Session } from "../../../common";
import { GetPreapprovalList } from "../../../services/preapprovalApi";
import { GetList } from "../../../services/enumsApi";
import { AddLeadDialog } from "../../popups";

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [showAddLead, setShowAddLead] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [statesList, setStatesList] = React.useState([]);
    const NavigateTo = useNavigate();

    React.useEffect(() => {
        setInitlized(true);
        Session.Remove("puserid");
        Session.Remove("ploanid");
        Session.Remove("loanid");
        Session.Remove("ploanref");
    }, []);

    const FetchLookUps = async () => {
        return new Promise(async (resolve) => {
            global.Busy(true);
            let idList = [18];
            let rslt = await GetList(idList);
            if (rslt.status === 100) {
                let t, _list;
                _list = rslt.data || [];
                t = _list.filter((x) => parseInt(x.type) === 18);
                Helper.AddSelectOption(t, true, 'value');
                setStatesList(t);
            }
            global.Busy(false);
            return resolve(true);
        });
    };

    const FetchResults = async () => {
        await FetchLookUps();
        global.Busy(true);

        let rslt = await GetPreapprovalList();
        if (rslt.status === 100) {
            let _data = rslt.data;
            Helper.SortByNumber(_data, '_id', true);
            setRows(_data);
        }
        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        setRows([]);
        FetchResults();
    }

    const OnLoadSelected = async (e, id, uid, num) => {
        e.preventDefault();
        await Session.StoreAsync("ploanref", num);
        await Session.StoreAsync("ploanid", id);
        await Session.StoreAsync("puserid", uid);
        NavigateTo("/leadd");
    }

    const OnAddLeadClicked = async (e) => {
        e.preventDefault();
        setShowAddLead(true);
    }

    const OnSubmitClicked = async (e) => {
        setShowAddLead(false);
        await FetchResults();
    };

    const OnCloseClicked = (e) => {
        setShowAddLead(false);
    };

    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix_10">
                    <div id="leadsBlk">
                        <div className="leadContentBlk">
                            <div className="lead_headblk">
                                <div className="blkName">Leads List</div>
                                <div className="head_right">
                                    <div className="rightVerBlock" style={{ display: "none" }}>
                                        <div className="filterSelectBox_100">
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT" disabled>Rate Lock</option>
                                                <option value="#">Yes</option>
                                                <option value="#">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="rightVerBlock" style={{ display: "none" }}>
                                        <div className="filterSelectBox_120">
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT" disabled>Loan Status</option>
                                                <option value="#">Active</option>
                                                <option value="#">Closed</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="rightVerBlock">
                                        <input type="button" className="rightButton" value="Add New Lead" onClick={(e) => OnAddLeadClicked(e)} />
                                    </div>
                                </div>
                            </div>

                            <div className="lead_filteredBlk" style={{ display: "none" }}>
                                <div className="lead_filter"><b>Rate Lock:</b> Yes<div className="fliter_close"><Link to="#" title="Close">&#215;</Link></div></div>
                                <div className="lead_filter"><b>Loan Status:</b> Active<div className="fliter_close"><Link to="#" title="Close">&#215;</Link></div></div>
                                <input type="button" className="lead_button_clear" value="Clear All" />
                            </div>

                            <div className="contentarea_table">
                                {(!rows || rows.length === 0) && (<div className="noContent">No Leads Found. Click add new leads</div>)}
                                {rows && rows.length > 0 && (
                                    <>
                                        <div className="leadListTable_head">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Sl.</td>
                                                        <td>Lead ID</td>
                                                        <td>Name</td>
                                                        <td>Email ID</td>
                                                        <td>Phone</td>
                                                        <td>Purpose</td>
                                                        <td>Property Details</td>
                                                        <td>Program</td>
                                                        <td>Amount</td>
                                                        <td>Created Date</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="leadListTable">
                                            <table>
                                                <tbody>
                                                    {rows && rows.map((x, index) => (
                                                        <tr key={x._id}>
                                                            <td>{index + 1}</td>
                                                            <td><Link to="#" onClick={(e) => OnLoadSelected(e, x._id, x.userid, x.refNumber)} >{x.refNumber}</Link></td>
                                                            <td>{x.Borrower}</td>
                                                            <td>{x.email}</td>
                                                            <td>{x.Phone}</td>
                                                            <td>{x.Purpose}</td>
                                                            <td>{x.Address}</td>
                                                            <td>{x.Program}</td>
                                                            <td>{x.LoanAmount}</td>
                                                            <td>{Helper.ToDateFormat(x.DateOn, "YYYY-MM-DD")}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <AddLeadDialog display={showAddLead} statesList={statesList}
                    onSubmittedNewLead={OnSubmitClicked} onCloseClicked={OnCloseClicked} />
            </Container>
        </>
    );
};

export default Component;
