import API from "./api";
import session from "../session";

const SetPreapproval = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`pre/setapproval`, input, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetPreapproval = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`pre/getapproval`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetPreapprovalList = async (id) => {
  let token = await session.GetHeader();
  let _query = `pre/getapproval/list`;
  if (id) {
    _query = `pre/getapproval/list/${id}`;
  }
  try {
    const res = await API.get(_query, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetPreapprovalStage = async (id, stageid) => {
  let token = await session.GetHeader();
  let _query = `pre/getapproval/stage/${id}/${stageid}`;
  try {
    const res = await API.get(_query, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetPreapprovalLetter = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`pre/getletter/${input}`, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetPreapprovalLetters = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`pre/getletters`, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetPreDocuments = async (id, all) => {
  let _type = all || 0;
  let token = await session.GetHeader();
  try {
    const res = await API.get(`pre/getdocuments/${id}/${_type}`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const DeletePreDocument = async (docid) => {
  let token = await session.GetHeader();
  try {
    const res = await API.delete(`pre/document/${docid}`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const AddPreDocument = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`pre/adddocument`, input, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

export {
  GetPreapproval, SetPreapproval,
  GetPreapprovalLetters, GetPreapprovalLetter,
  GetPreapprovalList, GetPreapprovalStage,
  GetPreDocuments, DeletePreDocument, AddPreDocument
};
